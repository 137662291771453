.exercises__header {
    margin-top: 10px;
    font-weight: bold;
}

.exercises__container {
    width: 100%;
}

.exercises__table {
    margin-top: 10px;
    border-collapse: collapse;
    width: 100%;
}

.exercises__table__header {
    background: #333;
    border-bottom: 3px solid #ccc;
}

.exercises__table__row {
    text-align: left;
    margin-left: 5px;
}

.exercises__link {
    cursor: pointer;
}

table th {
    padding: 15px 10px;
    color: #fff
}

table td {
    padding: 15px 10px;
    background-color: #fff;
}

.exercises__img {
    width: 300px;
    height: 200px;
}

.table-tr__th th{
    border: 1px solid grey;
}

.table-tr__td td {
    border: 1px solid #bbbbbb;
}