.train__form-container {
    zoom: 1;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 2px hsl(38deg 16% 76% / 50%);
    margin-bottom: 10px;
    margin-top: 20px;
    overflow: hidden;
    padding: 20px;
    position: relative;
    z-index: 1;
}

.train__container__head {
    display: flex;
    justify-content: space-between;
}

.train__header {
    font-size: 18px;
    margin-top: 10px;
}

.back-button {
    content:'';
    width:30px;
    height:30px;
    border: 0;
    margin-top: 10px;
    margin-right: 10px;
    background:url(./images/back.svg) 0 0 no-repeat;
}

.train__form-container__name {
    color: #777;
    display: block;
    width: 400px;
    margin: 0 0 5px;
    font-size: 11px;
}

.train__form-container__input {
    background: #fffcf5;
    border: 1px solid #ccc5b9;
    border-radius: 4px;
    box-shadow: none;
    display: block;
    padding: 10px;
    transition: background-color .3s ease 0s;
    width: 450px;
}

.train__form-container__button {
    background: #333;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    height: 40px;
    margin-left: 5px;
    margin-right: 20px;
    width: 160px;
}

.train__form-container__button-container {
    background: #f4f3ef;
    border-top: 1px solid #ccc;
    bottom: 0;
    margin-left: -30px;
    padding: 15px 10px;
    position: fixed;
    width: 100%;
}

.train__form-container__grid {
    display: grid;
    gap: 7px;
    margin-bottom: 50px;
}

.train__common__container {
    display: grid;
    gap: 10px;
    /*grid-template:  'one one one one'*/
    /*                'two two two two'*/
    /*                'three three three three'*/
    /*                'four four four four';*/
}

.train__form-container__name-header {
    color: #777;
    display: block;
    width: 400px;
    margin: 0 0 5px;
    font-size: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
}

.train__form-container__name-header-set {
    color: #777;
    display: block;
    width: 400px;
    margin-top: 10px;
    font-size: 15px;
    padding-left: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
}

.train__form-container__name-header-two {
    color: #777;
    display: block;
    width: 400px;
    margin-top: 10px;
    font-size: 15px;
    padding-left: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
}

.train__form-container__name-header-set-video {
    color: #777;
    display: block;
    width: 400px;
    font-size: 15px;
    padding-left: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    margin-bottom: 15px;
}

.train__exercise__container,
.train__set__container {
    display: grid;
    gap: 10px;
    grid-template-areas:
                        'one one one'
                        'two two two'
                        'three three three'
                        'four four four'
                        'five five five';
}

.exercise__one,
.set__one {
    grid-template: 'one';
}

.exercise__two,
.set__two {
    grid-template: 'two';
}

.exercise__three,
.set__three {
    grid-template: 'three';
}

.exercise__four,
.set__four {
    grid-template: 'four';
}

.exercise__five,
.set__five {
    grid-template: 'five';
}

.gap {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mt {
    margin-top: 10px;
}

.mb {
    margin-bottom: 10px;
}