html,body,ul,ol,li,dl,dt,dd,p,pre,h1,h2,h3,h4,h5,h6,blockquote,form,fieldset,legend,table,td,th {margin:0;padding:0;}
html,body {width:100%;height:100%}
body {overflow-x:hidden;min-height:100%;background:#fff;color:#000;font:14px/1.5 Arial,'Helvetica Neue',Helvetica,sans-serif;display:flex;flex-direction:column;height:100vh;}
html {scroll-behavior:smooth;}

img {margin:0;border-width:0;padding:0;}
ul {list-style:none;}

* {box-sizing:border-box;outline:none;}
* {touch-action:manipulation;}



#root {display:flex;flex-direction:column;height:100%;width: 100%}

body {
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}