.categories__form-container__select {
    margin-bottom: 5px;
    background: #fffcf5;
    border: 1px solid #ccc5b9;
    border-radius: 4px;
    box-shadow: none;
    display: block;
    padding: 10px;
    transition: background-color .3s ease 0s;
    width: 450px;
}