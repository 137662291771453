.add__item__container__row {
    display: flex; /* Используем Flexbox */
    align-items: center; /* Выравнивание элементов по вертикали по центру */
    justify-items: center;
    gap: 20px; /* Расстояние между элементами в ряду */
    width: 50%;
}

.add__item__delete-button {
    background: #333;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    height: 40px;
    width: 160px;
    cursor: pointer;
    margin-top: 20px;
}

.add__item__add-button {
    background: #333;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    height: 40px;
    width: 80px;
    cursor: pointer;
}

.add__item__size__description {
    font-size: 11px;
}