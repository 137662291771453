.item__characters__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.item__characters__block,
.item__images__block
{
    width: calc(50% - 10px); /* Распределение блоков в 3 колонки с промежутками */
}

.item__difficult__elements__header {
    color: #777;
    display: block;
    width: 400px;
    font-size: 15px;
    font-weight: 550;
}

.item__form-container__button {
    background: #333;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    height: 40px;
    margin-left: 5px;
    margin-right: 20px;
    width: 160px;
}

.item__main__container {
    margin-bottom: 100px;
}

.item__images__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Разделите на два столбца */
    gap: 16px; /* Промежуток между изображениями */
}

.item__image__wrapper {
    max-width: 100%; /* Ширина изображения внутри контейнера */
}
