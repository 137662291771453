.settings__form-container__input {
    background: #fffcf5;
    border: 1px solid #ccc5b9;
    border-radius: 4px;
    box-shadow: none;
    display: block;
    padding: 10px;
    transition: background-color .3s ease 0s;
    width: 450px;
    margin-bottom: 10px;
}

.mt {
    margin-top: 10px;
}

.settings__checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.settings__large-checkbox-input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.settings__label-text {
    font-family: Arial, sans-serif;
    font-size: 18px;
    color: #333;
    padding-top: 2px;
}

.settings__checkbox__description {
    margin-bottom: 10px;
}

.settings__checkbox__header {
    margin-bottom: 5px;
}

.settings__form-container__button {
    background: #333;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    height: 40px;
    margin-left: 5px;
    margin-right: 20px;
    width: 160px;
    cursor: pointer;
}

.settings__form-container__button-container {
    background: #f4f3ef;
    border-top: 1px solid #ccc;
    bottom: 0;
    margin-left: -10px;
    padding: 15px 10px;
    position: fixed;
    width: 100%;
}