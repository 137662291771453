.complex__form-container {
    zoom: 1;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 2px hsl(38deg 16% 76% / 50%);
    margin-bottom: 10px;
    margin-top: 20px;
    overflow: hidden;
    padding: 20px;
    position: relative;
    z-index: 1;
}

.complex__header {
    font-size: 18px;
    margin-top: 10px;
}

.back-button {
    content:'';
    width:30px;
    height:30px;
    border: 0;
    margin-top: 10px;
    margin-right: 10px;
    background:url(./images/back.svg) 0 0 no-repeat;
}

.complex__form-container__name {
    color: #777;
    display: block;
    width: 400px;
    margin: 0 0 5px;
    font-size: 11px;
}

.complex__form-container__input {
    background: #fffcf5;
    border: 1px solid #ccc5b9;
    border-radius: 4px;
    box-shadow: none;
    display: block;
    padding: 10px;
    transition: background-color .3s ease 0s;
    width: 450px;
}

.complex__form-container__button {
    background: #333;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    height: 40px;
    margin-left: 5px;
    margin-right: 20px;
    width: 160px;
}

.complex__form-container__button-container {
    background: #f4f3ef;
    border-top: 1px solid #ccc;
    bottom: 0;
    margin-left: -30px;
    padding: 15px 10px;
    position: fixed;
    width: 100%;
}

.complex__form-container__grid {
    display: grid;
    gap: 7px;
    margin-bottom: 50px;
}

/*.complexes__form-container__img {*/
/*    !*display: flex;*!*/
/*    !*flex-wrap: wrap;*!*/
/*    !*justify-content: flex-end;*!*/
/*    !*align-items: center;*!*/
/*    display: grid;*/
/*    grid-template-areas:    "one two"*/
/*                            "three four"*/
/*                            "five six";*/
/*    justify-items: end;*/
/*    gap: 10px;*/
/*}*/

/*.complex__form-container {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*}*/

/*.complexes__form-container__img__item {*/
/*    width: 70%;*/
/*}*/

circle__img__one,
.warm__one,
.circle__one {
    grid-template: 'one';
}

circle__img__two,
warm__two,
.circle__two {
    grid-template: 'two';
}

circle__img__three,
.warm__three,
.circle__three {
    grid-template: 'three';
}
.warm__four,
.circle__four {
    grid-template: 'four';
}

.head__edit {
    display: flex;
}

.complex__container__head {
    display: flex;
    justify-content: space-between;
}

.complex__form-container__input-same {
    background: #fffcf5;
    border: 1px solid #ccc5b9;
    border-radius: 4px;
    box-shadow: none;
    display: inline-block;
    padding: 10px;
    transition: background-color .3s ease 0s;
    width: 450px;
    margin-bottom: 5px;
    margin-right: 10px;
}

.complex__form-container__name-header {
    color: #777;
    display: block;
    width: 400px;
    margin: 0 0 5px;
    font-size: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
}

.complex__form-container__name-span {
    font-size: 15px;
    color: #777;
}

.warm__container {
    display: grid;
    grid-template:  'one one one'
                    'two two two'
                    'three three three';
}

.circle {
    padding-left: 50px;
}

.circle__container {
    display: grid;
    grid-template:  'one one one'
                    'two two two'
                    'three three three'
                    'four four four';
}

.warm__video {
    margin-top: 10px;
}

.complexes__form-container__img__item {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 70%;
}

.circle__video__header {
    margin-top: 10px;
    margin-bottom: 10px;
}

.complex__circle_img {
    display: grid;
    grid-template-areas:    'one one'
                            'two two'
                            'three three';
}
