.form-container {
	margin-top:20px;
	margin-bottom:10px;
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
	position:relative;
	z-index:1;
	zoom:1;
	overflow:hidden;
}
.form-container .cell {
	margin:0 20px 20px 0;
}
.form-container .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.form-container .cell-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.form-container .cell-oneline .cell {
	width:200px;
}
.form-container .notice {
	margin-bottom:10px;
	padding:10px;
	background:#fffcc0;
}
.form-container input, .form-container select, .form-container textarea {
	display:block;
	width:215px;
	padding:10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.form-container textarea {
	width:685px;
}
.form-container input:focus, .form-container select:focus, .form-container textarea:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.form-container select {-moz-appearance:none;-webkit-appearance:none;appearance:none;}
.form-container select.select-minimal {
	background-image:linear-gradient(45deg, transparent 50%, gray 50%),linear-gradient(135deg, gray 50%, transparent 50%),linear-gradient(to right, #ccc, #ccc);
	background-position:calc(100% - 20px) calc(1em + 2px),calc(100% - 15px) calc(1em + 2px),calc(100% - 2.5em) 0.5em;
	background-size:5px 5px,5px 5px,1px 1.5em;
	background-repeat:no-repeat;
}
.form-container select.select-minimal:focus {
	background-image:linear-gradient(45deg, #777 50%, transparent 50%),linear-gradient(135deg, transparent 50%, #777 50%),linear-gradient(to right, #ccc, #ccc);
	background-position:calc(100% - 15px) 1em,calc(100% - 20px) 1em,calc(100% - 2.5em) 0.5em;
	background-size:5px 5px,5px 5px,1px 1.5em;
	background-repeat: no-repeat;
}

.user__button {
	background: #333;
	border: none;
	border-radius: 3px;
	color: #fff;
	font-size: 16px;
	height: 40px;
	margin-left: 5px;
	margin-right: 20px;
	width: 160px;
}